<template>
  <b-container class="page-container login">
    <b-row class="justify-content-md-center">
      <b-col sm="12" lg="6" xl="6" class="pt-5">
        <h1>Inloggen</h1>
        <p>
          Log in alvorens je toegang krijgt tot je reserveringen, serienummers
          en andere informatie.
        </p>
        <b-card
          header="LOGIN"
          header-bg-variant="dark"
          header-text-variant="white"
        >
          <b-form @submit.prevent="loginFormSubmit">
            <b-form-group
              id="input-group-1"
              label="E-mailadres"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="form.username"
                type="text"
                placeholder="E-mailadres"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-2"
              label="Wachtwoord"
              label-for="input-2"
            >
              <b-input-group class="mt-3">
                <b-form-input
                  v-model="form.password"
                  placeholder="Wachtwoord"
                  required
                  :type="showPassword ? 'text' : 'password'"
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    title="Wachtwoord tonen"
                    variant="primary"
                    @click="showPasswordToggle()"
                    ><font-awesome-icon v-if="!showPassword" fas icon="eye"/>
                    <font-awesome-icon v-if="showPassword" fas icon="eye-slash"
                  /></b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <b-row>
              <b-col xs="12" sm="6">
                <b-button class="mb-2" type="submit" variant="primary"
                  >Inloggen
                  <font-awesome-icon
                    v-if="processing"
                    class="fa-spin"
                    far
                    :icon="['fad', 'spinner']"
                /></b-button>
              </b-col>
              <b-col xs="12" sm="6">
                <router-link
                  class="float-right"
                  :to="{ name: 'forgotPassword' }"
                  >Wachtwoord vergeten?</router-link
                >
              </b-col>
            </b-row>
          </b-form>
          <hr v-if="error" />
          <b-alert v-if="error" show variant="danger" class="mb-0">
            {{ error }}
          </b-alert>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { login } from '@/services/AuthorizationService'
import {
  BContainer,
  BCol,
  BButton,
  BRow,
  BInputGroupAppend,
  BFormInput,
  BInputGroup,
  BFormGroup,
  BForm,
  BCard,
  BAlert
} from 'bootstrap-vue'

export default {
  components: {
    BContainer,
    BCol,
    BButton,
    BRow,
    BInputGroupAppend,
    BFormInput,
    BInputGroup,
    BFormGroup,
    BForm,
    BCard,
    BAlert
  },
  data() {
    return {
      form: {
        username: '',
        password: ''
      },
      processing: false,
      error: false,
      showPassword: false
    }
  },
  metaInfo: {
    title: `Inloggen`
  },
  created: function() {
    if (this.$route.params.registered) {
      this.notify({ message: 'Account aanvraag succesvol' })
    }
  },
  methods: {
    notify({ message }) {
      this.$bvToast.toast(message, {
        autoHideDelay: 5000,
        appendToast: false,
        title: 'success!',
        toaster: 'b-toaster-bottom-right'
      })
    },
    async loginFormSubmit() {
      this.processing = true

      const result = await login({
        username: this.form.username,
        password: this.form.password
      })

      if (result) {
        this.error = result
      }

      this.processing = false
    },
    showPasswordToggle: function() {
      if (this.showPassword) {
        this.showPassword = false
      } else {
        this.showPassword = true
      }
    }
  }
}
</script>
<style lang="scss">
.login .card {
  border: 0px !important;
  box-shadow: 0 2px 4px 0 #0000001a;

  padding-bottom: 10px;
}
</style>
